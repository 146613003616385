.feedback-form {
  form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 1.5rem;
    }
  }
}
