@use "src/shared/layout/colors";

.criterium-detail {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.criterium-detail-button-active {
  border-color: colors.$blue;
  background-color: colors.$blue-xlight;
}

.criterium-detail-button-active:hover {
  border-color: colors.$blue-dark;
  background-color: colors.$blue-light;
}

.criterium-more-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  li a {
    word-break: break-all;
  }

  li {
    list-style: disc;
    list-style-position: inside;
    list-style-type: disc;
  }
}
