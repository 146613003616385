@use "src/shared/layout/colors";
@use "src/shared/layout/breakpoints";

/** Button Styles **/
button,
.button,
.button:link,
.button:visited {
  display: block;
  padding: 0.4rem 0.8rem;
  background-color: colors.$gray-xlight;
  border: solid colors.$gray 0.1rem;
  border-radius: 0.3rem;
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  // display: inline-block;
  transition-duration: 0.3s;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  @media screen and (max-width: breakpoints.$phone) {
    padding: 0.6rem 1rem;
  }
}

button:hover,
.button:hover {
  background-color: colors.$gray-light;
  border-color: colors.$gray-dark;
}

.button:focus-visible,
button:focus-visible {
  outline: colors.$red 0.25rem solid;
}

button:active,
.button:active {
  background-color: rgb(195, 193, 193);
}

button:disabled,
.button:disabled {
  background-color: colors.$gray-xlight;
  border-color: colors.$gray-light;
  color: grey;
}

button:disabled:hover,
.button:disabled:hover {
  background-color: rgb(197, 156, 156);
  color: grey;
}

.button-selected,
.button-selected:link,
.button-selected:visited {
  background-color: colors.$green;
  border-color: colors.$green-dark;
}

.button-selected:hover {
  background-color: colors.$green-light;
  border-color: colors.$green-dark;
}

.button-primary:visited,
.button-primary:link,
.button-primary {
  background-color: colors.$blue;
  border-color: colors.$blue-dark;
  color: white;
}

.button-primary:disabled {
  background-color: rgb(71, 108, 141);
  border-color: rgb(240, 248, 255);
  color: rgb(201, 216, 222);
}

.button-primary:hover {
  background-color: colors.$blue-dark;
  border-color: colors.$blue;
}

.button-wide {
  width: 100%;
}

.icon-button {
  display: flex;
  gap: 0.7rem;
  align-items: center;
  justify-content: center;
}

.button-with-icon {
  display: grid;
  gap: 0.7rem;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 0.5rem;
}
