@use "src/shared/layout/breakpoints";

.libraries {
  .library-header {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }

  .library-help {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .library-list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin: 1rem 0;
  }

  #filters {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    display: flex;
    gap: 0.7rem;
    flex-direction: row;

    .filter-group {
      display: flex;
      gap: 0.7rem;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media screen and (max-width: breakpoints.$phone) {
      flex-direction: column;
    }
  }

  .spacer-flex {
    flex-grow: 1;
  }
  .spacer {
    width: 0.5rem;
  }
}
