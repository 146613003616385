@use "src/shared/layout/colors";

.add-version-form {
  border: solid colors.$gray-xlight 0.125rem;
  border-radius: 0.5rem;
  padding: 0.7rem;
  width: 100%;
  .form {
    align-items: stretch;
  }
}

.add-version-form-small-label {
  grid-column: 1 / span 2;
  text-align: left;
  font-size: 0.9rem;
}
