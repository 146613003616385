/* General */
@use "src/shared/layout/colors";
@use "src/shared/layout/buttons";
@use "src/shared/layout/breakpoints";

@font-face {
  font-family: "AtkinsonHyperlegible";
  src: local("AtkinsonHyperlegible-Regular"),
    url("../fonts/AtkinsonHyperlegible-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AtkinsonHyperlegible";
  src: local("AtkinsonHyperlegible-Bold"),
    url("../fonts/AtkinsonHyperlegible-Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  font-family: "AtkinsonHyperlegible", Verdana, Geneva, Tahoma, sans-serif;
}

body {
  overflow-y: scroll;
}

.container {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 850px;
}

:focus {
  outline-color: colors.$red-dark;
  outline-width: 1.25rem;
}

:link {
  color: colors.$green-xdark;
}

:visited {
  color: colors.$green-xdark;
}

// accessibility class for span element
.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* responsive 2 column layout class */
.layout-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media screen and (max-width: breakpoints.$tablet) {
  .layout-split {
    display: flex;
    flex-direction: column;
  }
}

/** Forms **/

.form {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.7rem;
  align-items: baseline;
}

.form label {
  text-align: right;
}

.form-control-center {
  grid-column-start: span 2;
  text-align: center;
}

hr {
  background-color: colors.$gray-xlight;
  border: 0;
  height: 0.125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 0;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.2rem;
}

p {
  margin-bottom: 0.3rem;
}

input,
select {
  font-size: 1rem;
  padding: 0.2rem 0.3rem;
  border-radius: 0.2rem;
  border: 1px solid lightgray;
}
