@use "src/shared/layout/breakpoints";
@use "src/shared/layout/colors";

#main {
  margin-top: 2rem;
  margin-bottom: 2rem;
  min-height: calc(100vh - 15rem);
}

.skip-link {
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip-link:focus {
  width: auto;
  height: auto;
}

.main-alert {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.page-header {
  border-bottom: 0.125rem colors.$gray-xlight solid;
  padding: 1rem 0;

  @media screen and (min-width: breakpoints.$tablet) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "title navigation user"
      "title navigation user";
  }

  @media screen and (max-width: breakpoints.$tablet) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    // row-gap: 0.5rem;
    grid-template-areas:
      "title menu"
      "navigation navigation"
      "user user";
  }

  gap: 0 1rem;
  align-items: center;

  .title {
    grid-area: title;
    display: flex;
    gap: 1rem;
    align-items: center;
    .page-header-title {
      text-decoration: none;
      color: #000;
    }
    .logo {
      max-height: 4rem;
      max-width: 4rem;
    }
  }

  nav {
    grid-area: navigation;
    display: flex;
    gap: 0.9rem;
    align-items: center;
    height: 100%;
    padding: auto;

    .nav-link {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      padding: 0.5rem 0.7rem;
      border-radius: 0.3rem;
      color: colors.$green-xdark;
      font-size: 1.2rem;
      // transition: ;
      // text-decoration: none;
      text-decoration: underline rgba(0, 0, 0, 0);
      transition: text-decoration-color 0.1s, background-color 0.2s ease;
    }

    .nav-link:hover {
      // text-decoration: underline;
      text-decoration-color: colors.$green-xdark;
      background-color: colors.$gray-xlight;
    }

    .nav-link-active,
    .nav-link-active:hover {
      text-decoration: underline;
      color: colors.$green-xdark;
    }
  }

  .user {
    grid-area: user;
    font-size: 0.9rem;
    color: rgb(106, 106, 106);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: end;
    gap: 0.3rem;
  }

  .menu-button {
    grid-area: menu;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  @media screen and (min-width: breakpoints.$tablet) {
    .menu-button {
      display: none;
    }
    .user {
      min-width: 5rem;
    }
  }

  @media screen and (max-width: breakpoints.$tablet) {
    nav {
      flex-direction: column;
      align-items: center;
      .nav-link {
        width: 100%;
        justify-content: center;
      }
    }
    .visible {
      padding: 0.5rem 0;
    }
    .hidden {
      visibility: hidden;
      height: 0;
      padding-bottom: none;
    }
    .user {
      flex-direction: row;
      justify-self: center;
    }
  }
}

.page-footer {
  min-height: 2rem;
  padding-bottom: 2rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  grid-template-areas: "ost sponsor links";

  .ost {
    grid-area: ost;
    .img-ost {
      height: 5rem;
    }
  }

  .sponsor {
    grid-area: sponsor;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .links {
    grid-area: links;
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.3rem;
    }
    li {
      text-align: right;
    }
  }

  .img-sponsor {
    max-height: 6rem;
    margin: 0 auto;
  }

  .nav-icon img {
    height: 1rem;
  }

  @media screen and (max-width: breakpoints.$tablet) {
    grid-template-columns: auto;
    grid-template-areas:
      "links"
      "ost"
      "sponsor";
    gap: 1.5rem;

    .ost {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      p {
        text-align: center;
      }
      .img-ost {
        max-height: 4rem;
      }
    }

    .links ul {
      align-items: center;
    }
  }
}
