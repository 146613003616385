@use "src/shared/layout/colors";

.components {
  .colors {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    .color {
      height: 3rem;
      border-radius: 0.5rem;
    }

    .green-xlight {
      background-color: colors.$green-xlight;
    }
    .green-light {
      background-color: colors.$green-light;
    }
    .green {
      background-color: colors.$green;
    }
    .green-dark {
      background-color: colors.$green-dark;
    }
    .green-xdark {
      background-color: colors.$green-xdark;
    }

    .blue-xlight {
      background-color: colors.$blue-xlight;
    }
    .blue-light {
      background-color: colors.$blue-light;
    }
    .blue {
      background-color: colors.$blue;
    }
    .blue-dark {
      background-color: colors.$blue-dark;
    }
    // .blue-xdark {
    //   background-color: colors.$blue-xdark;
    // }

    // .red-xlight {
    //   background-color: colors.$red-xlight;
    // }
    .red-light {
      background-color: colors.$red-light;
    }
    .red {
      background-color: colors.$red;
    }
    .red-dark {
      background-color: colors.$red-dark;
    }
    // .red-xdark {
    //   background-color: colors.$red-xdark;
    // }

    // .yellow-xlight {background-color: colors.$yellow-xlight;}
    .yellow-light {
      background-color: colors.$yellow-light;
    }
    .yellow {
      background-color: colors.$yellow;
    }
    // .yellow-dark {
    //   background-color: colors.$yellow-dark;
    // }
    // .yellow-xdark {
    //   background-color: colors.$yellow-xdark;
    // }

    .gray-xlight {
      background-color: colors.$gray-xlight;
    }
    .gray-light {
      background-color: colors.$gray-light;
    }
    .gray {
      background-color: colors.$gray;
    }
    .gray-dark {
      background-color: colors.$gray-dark;
    }
    // .gray-xdark {background-color: colors.$gray-xdark;}

    // .COLOR-xlight {background-color: colors.$COLOR-xlight;}
    // .COLOR-light {background-color: colors.$COLOR-light;}
    // .COLOR {background-color: colors.$COLOR;}
    // .COLOR-dark {background-color: colors.$COLOR-dark;}
    // .COLOR-xdark {background-color: colors.$COLOR-xdark;}
  }
}
