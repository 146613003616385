@use "src/shared/layout/breakpoints";
@use "src/shared/layout/colors";

.faq {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

#faq {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;

  .question {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    gap: 1rem;
    @media screen and (max-width: breakpoints.$tablet) {
      grid-template-columns: 1fr;
    }
  }

  p {
    font-size: 1.2rem;
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 0;
    column-span: 2;
  }

  h3 {
    font-style: oblique;
    color: colors.$green-xdark;
  }
}
