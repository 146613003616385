@use "src/shared/layout/colors";
@use "src/shared/layout/breakpoints";

.library-card {
  display: grid;
  border: 0.125rem solid colors.$gray-xlight;
  column-gap: 1rem;
  row-gap: 0.7rem;
  margin: 0rem;
  padding: 0.9rem 0.9rem;
  border-radius: 0.5rem;
  align-items: center;

  .header {
    grid-area: title;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: baseline;
    h3,
    p {
      margin-bottom: 0;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    grid-area: score;

    .main-scores {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    .filter-scores {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: baseline;
    }
  }

  .aside {
    grid-area: ctrl;
  }

  @media screen and (min-width: breakpoints.$tablet) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "title ctrl"
      "score ctrl";
  }

  @media screen and (min-width: breakpoints.$phone) and (max-width: breakpoints.$tablet) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "title ctrl"
      "score ctrl";

    .header {
      gap: 1rem;
    }
  }

  @media screen and (max-width: breakpoints.$phone) {
    grid-template-columns: auto;
    grid-template-areas:
      "title"
      "score"
      "ctrl";

    .header {
      gap: 1rem;
    }
  }
}

.library-card-true {
  border-color: colors.$green-light;
}

.library-card-neutral {
  border-color: colors.$yellow;
  .header {
    color: rgb(66, 66, 66);
  }
}

.library-card-false {
  border-color: colors.$red-light;

  .header {
    color: rgb(84, 84, 84);
  }
}
