@use "src/shared/layout/breakpoints";

.library-detail {
  .backnav {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    width: fit-content;
    a {
      display: flex;
      align-items: center;
    }
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
    row-gap: 0.7rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    h2 {
      margin-bottom: 0;
    }
  }

  .lib-detail-help {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
  }

  .testresults {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1.2rem;
  }

  .more-components-alert {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    align-items: flex-start;
    h2,
    p {
      margin: 0;
    }
  }
}
