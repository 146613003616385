.contribute {
  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
