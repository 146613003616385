@use "src/shared/layout/breakpoints";
@use "src/shared/layout/colors";

.home {
  .home-intro {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 2rem;
    font-size: 1.4rem;

    h1 {
      color: colors.$green-xdark;
      font-size: 3rem;
    }
  }

  .home-cact {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    p {
      margin-bottom: 0;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      .highlight {
        font-size: 1.5rem;
        color: colors.$green-xdark;
        span {
          font-weight: 700;
        }
      }

      .description {
        text-align: center;
        font-size: 1.3rem;
      }
    }

    @media screen and (min-width: breakpoints.$desktop) {
      .image {
        max-width: 7.5rem;
      }
    }

    @media screen and (min-width: breakpoints.$tablet) and (max-width: breakpoints.$desktop) {
      display: flex;
      flex-direction: column;

      .image {
        max-width: 6rem;
      }
      .cactus-text {
        min-width: 14rem;
      }
      .item {
        flex-direction: row;
        justify-content: center;
      }
    }

    @media screen and (max-width: breakpoints.$tablet) {
      display: flex;
      flex-direction: column;

      .image {
        max-width: 5rem;
      }
      .cactus-text {
        width: 11rem;
      }
      .item {
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  .home-section {
    display: grid;
    padding: 1rem;
    align-items: center;

    .button {
      grid-area: button;
      font-size: 1.2rem;
      justify-self: left;
    }

    .title {
      grid-area: title;
      font-size: 2rem;
      justify-self: right;
      h3 {
        text-align: right;
      }
    }

    .text {
      grid-area: text;
      font-size: 1.3rem;
    }

    @media screen and (min-width: breakpoints.$tablet) {
      grid-template-columns: 1fr 2fr;
      grid-template-areas:
        "title text"
        "title button";
      column-gap: 1.5rem;
      row-gap: 0;
    }

    @media screen and (max-width: breakpoints.$tablet) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "title"
        "text"
        "button";
      column-gap: 0;
      row-gap: 0.7rem;

      .title {
        justify-self: center;
      }

      .button {
        justify-self: center;
      }
    }
  }
}
