@use "../layout/colors";

.bubble,
.bubble-value {
  padding: 0.4rem 0.7rem;
  border-radius: 1rem / 50%;
}

.bubble {
  display: flex;
  color: black;
  background-color: colors.$gray-xlight;
  max-height: 2.05rem;
}

.bubble-value {
  margin: -0.4rem -0.7rem;
  margin-right: 0.4rem;
  min-width: 2.05rem;
  text-align: center;
  background-color: colors.$gray-light;
}

.bubble-green .bubble-value {
  background-color: colors.$green;
}

.bubble-green {
  background-color: colors.$green-light;
}

.bubble-green-light .bubble-value {
  background-color: colors.$green-light;
}

.bubble-green-light {
  background-color: colors.$green-xlight;
}

.bubble-blue .bubble-value {
  background-color: colors.$blue-dark;
}

.bubble-blue {
  color: white;
  background-color: colors.$blue;
}

.bubble-red .bubble-value {
  background-color: colors.$red;
}

.bubble-red {
  background-color: colors.$red-light;
}

.bubble-yellow {
  background-color: colors.$yellow-light;
}

.bubble-yellow .bubble-value {
  background-color: colors.$yellow;
}
