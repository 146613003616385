.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .oauth {
    max-width: 25rem;
  }

  form {
    min-width: 25rem;
  }
}
