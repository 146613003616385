@use "src/shared/layout/breakpoints";
@use "src/shared/layout/colors";

/** general **/

.lab-header {
  .page-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      "logo title test-data"
      "logo title test-data"
      "logo title test-data";
    @media screen and (max-width: breakpoints.$phablet) {
      row-gap: 1rem;
      grid-template-areas:
        "logo title title"
        "test-data test-data test-data"
        "test-data test-data test-data";
    }
  }

  .logo {
    grid-area: logo;
    max-height: 5rem;
    width: 5rem;
  }

  .title {
    grid-area: title;
  }

  .test-data {
    grid-area: test-data;
    min-width: 14rem;
  }
}

#lab-main {
  padding-top: 1.3rem;
  margin-bottom: 2rem;
  .lab-layout {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }

  .control-group {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.7rem;
    align-items: stretch;
    background-color: colors.$blue-light;
    padding: 1rem;
    border-radius: 0.7rem;
  }

  textarea {
    padding: 0.7rem;
    border: 0.125rem solid colors.$gray-dark;
    border-radius: 0.3rem;
    font-size: 1rem;
    max-width: 100%;
    max-height: 20rem;
  }
}
