@use "src/shared/layout/colors";

.alert {
  border-radius: 0.4rem;
  padding: 0.7rem 0.9rem;
  // margin-bottom: 0.5rem;
}

.alert-info {
  background-color: colors.$yellow-light;
}

.alert-help {
  background-color: colors.$blue-light;
}

.alert-success {
  background-color: colors.$green-xlight;
}

.alert-error {
  background-color: colors.$red-light;
}

.alert-with-icon {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  align-items: center;

  p {
    margin: 0;
  }

  svg {
    width: 1rem;
    flex-shrink: 0;
  }
}
