@use "src/shared/layout/colors";

.loading-spinner {
  background-color: colors.$gray-xlight;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-self: center;
  justify-content: center;
  border-radius: 0.7rem;
  .text {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 0.7rem;
    row-gap: 0.3rem;
  }
  .spinner {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
