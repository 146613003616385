@use "src/shared/layout/colors";
@use "src/shared/layout/breakpoints";

.criteria-reference {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  h2,
  h3 {
    margin: 0;
  }

  p {
    max-width: 40rem;
  }

  .component {
    .component-header {
      display: grid;
      @media screen and (min-width: breakpoints.$phone) {
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto 1fr;
        row-gap: 0.3rem;
      }
      column-gap: 1rem;

      .img {
        height: 6rem;
        grid-row: span 2;

        // switch easter egg
        svg,
        img {
          width: 6rem;
          height: 6rem;
        }
      }
      button {
        grid-row: span 2;
        align-self: center;
        width: 7rem;
      }
      @media screen and (max-width: breakpoints.$phone) {
        grid-template-columns: auto 1fr;
        p {
          grid-column: span 2;
        }
        button {
          width: 100%;
        }
      }
    }
  }

  .focus {
    background-color: colors.$green-xlight;
    padding: 0.8rem;
    border-radius: 0.7rem;
  }

  .testmode {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin: 1.2rem 0;
  }

  .criterion {
    display: flex;
    flex-direction: column;

    background-color: white;
    border: 0.125rem solid colors.$gray-xlight;
    border-radius: 0.5rem;
    padding: 0.7rem;

    .criterion-title {
      font-size: 1.4rem;
    }

    li a {
      word-break: break-all;
    }

    li {
      list-style: disc;
      list-style-position: outside;
      list-style-type: disc;
      margin-left: 1.5rem;
    }
  }
}
