#testlab-preparation {
  section {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }

  .subsection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  p {
    max-width: 40rem;
  }

  .select-screenreader {
    display: flex;
    align-items: baseline;
    gap: 0.7rem;
    flex-wrap: wrap;
  }

  h2 {
    margin-bottom: 0;
    margin-top: 0.7rem;
  }

  input[type="text"] {
    max-width: 35rem;
  }

  .test-instruction-img {
    height: 10rem;
    width: 14rem;
    justify-self: center;
  }
}
